<template lang="pug">
    v-container.search
        h1.mb-4 Search Recent Bansko Property Listings
        v-card.pa-5.mb-3(color="white" flat)
            v-row
                v-col(cols="12" md="6")
                    v-text-field(outlined x-large hide-details v-model="searchTerm" placeholder="Pirin" @input="searched = false" @keydown.space.prevent @keydown.enter="search(searchTerm)")
                v-col(cols="12" md="6")
                    v-btn(x-large color="primary" @click="search(searchTerm)") Search
                v-col(cols="12")
                    p.mx-2 Note: we can only search titles and descriptions for complete, single words. Not case-sensitive.
                    p.mx-2 For example, "Mountain" (not "Mountain Dream" or "Mount").
                    p.mx-2.mb-0 Searching for 'bedroom' (singular) might return different results than 'bedroomS' (plural)

        v-expand-transition
            v-card#results(color="transparent" flat v-if="searchTerm && searched").mt-6
                template(v-if="loading")
                    v-row
                        v-col(cols="12" md="6" v-for="n in 10", :key="n")
                            v-skeleton-loader(type="card")

                template(v-else)
                    template(v-if="results && results.length > 0")
                        h2 Found {{results.length}} {{results.length === 1 ? 'Listing' : 'Listings'}}
                        v-row
                            v-col(cols="12" md="6", v-for="result in results", :key="result.permalink")
                                v-sheet(height="auto")
                                    ListingCard(:listing="result")
                    template(v-else)
                        v-alert(color="accent" border="left" outlined) Sorry, we couldn't find any listings for [ {{searchTerm}} ]

</template>

<script>
import uniqBy from "lodash.uniqby";
import db from "@/plugins/db.js";
const ListingCard = () => import("@/components/ListingCard.vue");
export default {
  name: "Search",
  components: { ListingCard },
  data() {
    return {
      searchTerm: "",
      results: [],
      loading: false,
      searched: false
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {},
    async search(term) {
      try {
        this.searched = true;
        this.loading = true;
        if (term && term.length > 0) {
          this.results = [];

          //   ollectionRef.where('name', '>=', 'bar').where('name', '<=', 'foo')
          const res1 = await db
            .collection("listings")
            .where("arrTitle", "array-contains", term.toLowerCase())
            .orderBy("created", "desc")
            .get();
          const res2 = await db
            .collection("listings")
            .where("arrDescription", "array-contains", term.toLowerCase())
            .orderBy("created", "desc")
            .get();

          let found = [];
          Promise.all([res1, res2]).then(done => {
            done.forEach(res => {
              const docs = res.docs.map(doc => doc.data());
              found.push(...docs);
            });

            this.results = uniqBy(found, "permalink");
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          document.getElementById("results").scrollIntoView();
        }, 400);

        this.loading = false;
      }
    }
  }
};
</script>
